<template>
  <div id="app">
    <div id="BodyContent" v-if="office == null">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center">
            <div class="loading-animation mx-auto">
              <div class="loading-animation-content">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="BodyContent" v-else>
      <div class="pt-8 bg-white" id="pageTitle">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-10">
              <h2 id="officeName" class="h2 font-weight-light text-primary">
                <span class="fas fa-building text-primary-10" v-if="office.OfficeType == 'FLD'"></span>
                <span class="fas fa-store-alt text-primary-10" v-if="office.OfficeType != 'FLD'"></span>
                {{ office.OfficeName }}
              </h2>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12 col-md-6">
              <hr class="major mb-0" />
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-6">
        <div class="row">
          <div class="col-12 text-center">
            <h3 class="h3" v-if="office.Status == 'Open'">
              Status:
              <span class="badge badge-pill badge-success">
                <span class="fas fa-check-circle"></span> Open
              </span>
            </h3>
            <h3 class="h3" v-if="office.Status == 'Closed'">
              Status:
              <span class="badge badge-pill badge-danger">
                <span class="fas fa-times-circle"></span> Closed
              </span>
            </h3>
            <h3 class="h3" v-if="office.Status == 'Temporary Office Closure'">
              Status:
              <span class="badge badge-pill badge-warning">
                <span class="fas fa-times-circle"></span> Temporary Office Closure
              </span>
            </h3>
          </div>
        </div>
      </div>

      <div id="infoBanner" class="container" v-if="office.OfficeType == 'PTA'">
        <div class="row">
          <div class="col-12">
            <div id="infoBannerContainer" class="alert alert-info mt-4 mb-0" role="alert">
              <div id="ptaFeeMessage">
                <span class="fas fa-info-circle"></span>
                <span class="pl-1">A convenience fee may be assessed for transactions
                performed by Public Tag Agents.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="messageBanner"
        class="container"
        :key="message"
        v-for="message in office.PublicMessages"
      >
        <div class="row">
          <div class="col-12">
            <div class="alert alert-info mt-4 mb-0" role="alert">
              <div>
                <div class="pl-1" v-html="addIcon(message)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="BodyContent">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-12">
              <div class="white-box">
                <div class="row">
                  <div class="col-md-6">
                    <h3 class="h3">Office Information</h3>
                    <ul class="list-group">
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-md-6">
                            <h4 class="h6 mb-2">Physical Address</h4>
                            <p id="officeAddress" class="align-items-start ml-1 mb-2">
                              {{ office.PhysicalAddress1 }}
                              <br v-if="office.hasOwnProperty('PhysicalAddress2')" />
                              {{ office.PhysicalAddress2 }}
                              <br />
                              {{ office.PhysicalCity }}, {{ office.PhysicalState }} {{ office.PhysicalZip }}
                            </p>
                            <a
                              v-bind:href="office.MapLink"
                              title="See this office on Google Maps"
                              class="btn btn-info btn-sm mb-6 mb-lg-0"
                              target="_blank"
                              v-if="office.hasOwnProperty('MapLink') && office.OfficeType == 'FLD'"
                            >
                              <span>See on Google Maps</span>
                              <span class="fas fa-map-marker-alt ml-1"></span>
                            </a>
                          </div>
                          <div id="mailingAddressGroup" class="col-md-6"
                            v-if="(office.MailingAddress1 && office.MailingCity && office.MailingState && office.MailingZip)">
                            <h4 class="h6 mb-2">Mailing Address</h4>
                            <p id="officeMailingAddress" class="align-items-start ml-1">
                              {{ office.MailingAddress1 }}
                              <br v-if="office.hasOwnProperty('MailingAddress2')" />
                              {{ office.MailingAddress2 }}
                              <br />
                              {{ office.MailingCity }}, {{ office.MailingState }} {{ office.MailingZip }}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <h4 class="h6 mb-2">Parish</h4>
                        <p id="officeParish" class="align-items-start ml-1">{{ office.Parish }}</p>
                      </li>
                      <li class="list-group-item">
                        <h4 class="h6 mb-2">Phone Numbers</h4>
                        <p id="officePhone" class="align-items-start ml-1">
                          <template v-for="PublicPhoneNumber in office.PublicPhoneNumbers">
                            {{ PublicPhoneNumber.Number }}
                            <template
                              v-if="PublicPhoneNumber.PhoneNumberExtension"
                            >Ext: {{ PublicPhoneNumber.PhoneNumberExtension }}</template>
                            <br />
                          </template>
                        </p>
                      </li>
                      <li class="list-group-item">
                        <h4 class="h6 mb-2">Fax Numbers</h4>
                        <p id="officeFax" class="align-items-start ml-1">
                          <template v-for="FaxPhoneNumber in office.FaxPhoneNumbers">
                            {{ FaxPhoneNumber.Number }}
                            <br />
                          </template>
                        </p>
                      </li>
                    </ul>
                    <template v-if="office.OfficeType == 'FLD' || office.OfficeType == 'PTA'">
                      <div class="col-12 d-md-none">
                        <hr class="minor" />
                      </div>
                      <h3 class="mt-4">Services</h3>
                      <ul id="servicesList" class="list-group">
                        <template v-if="office.Services.length > 0">
                          <li
                            v-for="service in servicesList"
                            class="list-group-item"
                            :key="service"
                          >{{ service }}</li>
                        </template>
                        <li
                          class="list-group-item"
                          v-else-if="office.OfficeType === 'PTA' && office.Status === 'Open'"
                        >Limited services available at this time.</li>
                        <li class="list-group-item" v-else>No services available at this time.</li>
                      </ul>
                    </template>
                  </div>
                  <div class="col-md-6 mt-4 mt-lg-0">
                    <h3>Operating Hours &amp; Days</h3>
                    <ul class="list-group" id="hoursList">
                      <li class="list-group-item">
                        <h4 class="h6 text-primary">Monday</h4>
                        <p class="mb-0" v-html="getScheduleDisplay('Monday')" />
                      </li>
                      <li class="list-group-item">
                        <h4 class="h6 text-primary">Tuesday</h4>
                        <p class="mb-0" v-html="getScheduleDisplay('Tuesday')" />
                      </li>
                      <li class="list-group-item">
                        <h4 class="h6 text-primary">Wednesday</h4>
                        <p class="mb-0" v-html="getScheduleDisplay('Wednesday')" />
                      </li>
                      <li class="list-group-item">
                        <h4 class="h6 text-primary">Thursday</h4>
                        <p class="mb-0" v-html="getScheduleDisplay('Thursday')" />
                      </li>
                      <li class="list-group-item">
                        <h4 class="h6 text-primary">Friday</h4>
                        <p class="mb-0" v-html="getScheduleDisplay('Friday')" />
                      </li>
                      <li class="list-group-item" v-if="getScheduleDisplay('Saturday') != 'Closed'">
                        <h4 class="h6 text-primary">Saturday</h4>
                        <p class="mb-0" v-html="getScheduleDisplay('Saturday')" />
                      </li>
                      <li class="list-group-item" v-if="getScheduleDisplay('Sunday') != 'Closed'">
                        <h4 class="h6 text-primary">Sunday</h4>
                        <p class="mb-0" v-html="getScheduleDisplay('Sunday')" />
                      </li>
                      <li
                        class="list-group-item"
                        v-if="office.HoursAndDaysNotes.length > 0"
                      >
                        <h4 class="h6 text-primary">Notes</h4>
                        <p class="mb-0" v-for="note in office.HoursAndDaysNotes" :key="note">{{ note }}</p>
                      </li>
                    </ul>
                    <div id="omvTestingGroups" v-if="office.OfficeType == 'FLD'">
                      <h3 class="mt-4">Testing</h3>
                      <ul class="list-group">
                        <li
                          id="cdlSkillsDescription"
                          class="list-group-item"
                          v-if="office.CDLTestingPublicMessage != null && office.CDLTestingPublicMessage != ''"
                        >
                          <h4 class="h5 text-primary">CDL Testing</h4>
                          <div v-html="office.CDLTestingPublicMessage"></div>
                        </li>
                        <li
                          id="cdlSkillsDescription"
                          class="list-group-item"
                          v-else-if="office.CDLTesting == true"
                        >
                          <h4 class="h5 text-primary">CDL Testing</h4>
                          <h4 class="h6 text-primary">By Appointment Only</h4>
                          <p
                            class="mb-0"
                          >Please call or visit the OMV office to schedule an appointment.</p>
                        </li>
                        <li id="cdlSkillsDescription" class="list-group-item" v-else>
                          <h4 class="h5 text-primary">CDL Testing</h4>CDL Testing is not available at this OMV office. Please contact an alternate OMV office to schedule an appointment at that location.
                        </li>
                        <li
                          id="roadSkillsDescription"
                          class="list-group-item"
                          v-if="office.RoadSkillsTestingPublicMessage != null && office.RoadSkillsTestingPublicMessage != ''"
                        >
                          <h4 class="h5 text-primary">Road Skills Testing</h4>
                          <div v-html="office.RoadSkillsTestingPublicMessage"></div>
                        </li>
                        <li
                          id="roadSkillsDescription"
                          class="list-group-item"
                          v-else-if="office.RoadSkillsTestingSchedule == true"
                        >
                          <h4 class="h5 text-primary">Road Skills Testing</h4>
                          <h4 class="h6 text-primary">By Appointment Only</h4>Please call or visit the OMV office to schedule an appointment.
                          <br />Note: The Road Skills Test will not be conducted during inclement weather.
                        </li>
                        <li id="roadSkillsDescription" class="list-group-item" v-else>
                          <h4 class="h5 text-primary">Road Skills Testing</h4>Road Skills Testing not available at this OMV office. Please contact an alternate OMV office to schedule an appointment at that location.
                        </li>
                        <li
                          id="knowledgeSkillsDescription"
                          class="list-group-item"
                          v-if="office.KnowledgeTestingPublicMessage != null && office.KnowledgeTestingPublicMessage != ''"
                        >
                          <h4 class="h5 text-primary">Knowledge Testing</h4>
                          <div v-html="office.KnowledgeTestingPublicMessage"></div>
                        </li>
                        <li
                          id="knowledgeSkillsDescription"
                          class="list-group-item"
                          v-else-if="office.KnowledgeTesting == true"
                        >
                          <h4 class="h5 text-primary">Knowledge Testing</h4>
                          <h4 class="h6 text-primary">By Appointment Only</h4>
                          <p
                            class="mb-0"
                          >Please call or visit the OMV office to schedule an appointment.</p>
                        </li>
                        <li id="knowledgeSkillsDescription" class="list-group-item" v-else>
                          <h4 class="h5 text-primary">Knowledge Testing</h4>Knowledge Testing is not available at this OMV office. Please contact an alternate OMV office to schedule an appointment at that location.
                        </li>
                        <li
                          id="oralSkillsDescription"
                          class="list-group-item"
                          v-if="office.OralTestingPublicMessage != null && office.OralTestingPublicMessage != ''"
                        >
                          <h4 class="h5 text-primary">Oral Testing</h4>
                          <div v-html="office.OralTestingPublicMessage"></div>
                        </li>
                        <li
                          id="oralSkillsDescription"
                          class="list-group-item"
                          v-else-if="office.OralTesting == true"
                        >
                          <h4 class="h5 text-primary">Oral Testing</h4>
                          <h4 class="h6 text-primary">By Appointment Only</h4>
                          <p
                            class="mb-0"
                          >Please call or visit the OMV office to schedule an appointment.</p>
                        </li>
                        <li id="oralSkillsDescription" class="list-group-item" v-else>
                          <h4 class="h5 text-primary">Oral Testing</h4>Oral Testing is not available at this OMV office. Please contact an alternate OMV office to schedule an appointment at that location.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseISO, format } from 'date-fns';

export default {
  data() {
    return {
      office: null
    };
  },
  computed: {
    officeId: function() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      return params.get("office");
    },
    servicesList: function() {
      return this.office.Services
       .sort(function(a, b) {
         return a.toUpperCase() > b.toUpperCase()
          ? 1
          : -1;
       });
    },
    schedule: function() {
      return {
        "Monday": this.office.HoursAndDays.filter(s => s.MondayStatus).map(s => this.shrinkSchedule(s)),
        "Tuesday": this.office.HoursAndDays.filter(s => s.TuesdayStatus).map(s => this.shrinkSchedule(s)),
        "Wednesday": this.office.HoursAndDays.filter(s => s.WednesdayStatus).map(s => this.shrinkSchedule(s)),
        "Thursday": this.office.HoursAndDays.filter(s => s.ThursdayStatus).map(s => this.shrinkSchedule(s)),
        "Friday": this.office.HoursAndDays.filter(s => s.FridayStatus).map(s => this.shrinkSchedule(s)),
        "Saturday": this.office.HoursAndDays.filter(s => s.SaturdayStatus).map(s => this.shrinkSchedule(s)),
        "Sunday": this.office.HoursAndDays.filter(s => s.SundayStatus).map(s => this.shrinkSchedule(s))
      }
    }
  },
  methods: {
    updateMotorcycleServices: function () {
      let services = this.office.Services;
      let idx = services.findIndex((s) => {return s.trim().toUpperCase() === 'Conduct Motorcycle Road Skills Test'.toUpperCase()});
      if (idx >= 0) {
        services.splice(idx, 1);
        services.push('Motorcycle Skills Road Test');
        this.office.Services = services.sort((a,b) => { return a.toLowerCase().localeCompare(b.toLowerCase())});
      }
    },
    formatTime: function(string) {
      return string == null
        ? ""
        : format(parseISO(string), "hh:mmaaa");
    },
    addIcon: function(html) {
      let element = document.createElement("div");
      let iconElement = document.createElement("span");

      element.innerHTML = html;
      iconElement.className = "fas fa-comment";

      if (element.firstElementChild.nodeName == "DIV") {
        element.firstElementChild.prepend(document.createTextNode('\u00A0'));
        element.firstElementChild.prepend(iconElement);
      } else {
        element.prepend(document.createTextNode('\u00A0'));
        element.prepend(iconElement);
      }
      return element.innerHTML;
    },
    shrinkSchedule: function (schedule) {
      return {
        "Open": schedule.Open,
        "Close": schedule.Close,
        "LunchStart": schedule.LunchStart,
        "LunchEnd": schedule.LunchEnd,
        "FirstWeek": schedule.FirstWeek,
        "SecondWeek": schedule.SecondWeek,
        "ThirdWeek": schedule.ThirdWeek,
        "FourthWeek": schedule.FourthWeek,
        "FifthWeek": schedule.FifthWeek
      }
    },
    hasAllWeeks: function (schedule) {
      return schedule.FirstWeek
        && schedule.SecondWeek
        && schedule.ThirdWeek
        && schedule.FourthWeek
        && schedule.FifthWeek
    },
    getScheduleDisplay: function (dayOfWeek) {
      let schedules = this.schedule[dayOfWeek];
      if (schedules.length === 0) {
        return "Closed"
      } 
      else if (schedules.length > 1 || !this.hasAllWeeks(schedules[0])) {
        return "See Notes"
      } 
      else {
        if (schedules[0].hasOwnProperty("LunchStart") && schedules[0].LunchStart != null) {
          return `${this.formatTime(
            schedules[0].Open
          )} - ${this.formatTime(
            schedules[0].LunchStart
          )}<br>${this.formatTime(
            schedules[0].LunchEnd
          )} - ${this.formatTime(schedules[0].Close)}`;
        }
        return `${this.formatTime(
          schedules[0].Open
        )} - ${this.formatTime(schedules[0].Close)}`;
      }
    }
  },
  mounted() {
    let request = new XMLHttpRequest();
    request.onload = function() {
      let offices = JSON.parse(request.responseText).Offices;
      this.office = offices.filter(function(office) {
        return office.Id == this.officeId;
      }, this)[0];
      this.updateMotorcycleServices();
    }.bind(this);
    let qs = new Date().toISOString();
    request.open("GET", `data.json?q=${qs}`, true);
    request.onerror = function() {};
    request.send();
  }
};
</script>

<style lang="scss">
  @import "@/_styles/scss/styles.scss";
</style>
