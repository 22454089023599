<template>
    <div>
        <vue-pelican-typeahead
            v-model="searchText"
            :items="choices"
            :item-label="item => `${item}`"
            :item-value="item => `${item}`"
            v-on:hit="onHit"
            v-on:search="onBlur($event)"
            :useClientsideFiltering="true"
            :disabled="disabled">
        </vue-pelican-typeahead>
        <small class="mt-1" v-if="modelValue">{{modelValue}}</small>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue';

    export default {
        props: {
            modelValue: {
                type: String
            },
            choices: {
                type: Array
            },
            disabled: {
                type: Boolean
            }
        },
        emits: ['update:modelValue'],
        components: {
            VuePelicanTypeahead: defineAsyncComponent(() => import('../../plugins/Vue.Pelican.Typeahead.vue'))
        },
        data: function () {
            return {
                searchText: ""
            };
        },
        watch: {
            modelValue: function (newVal) {
                if (newVal != null && newVal != '') {
                    this.searchText = newVal;
                }
                else {
                    this.searchText = "";
                }
            },
            searchText: function (newVal) {
                this.$emit('update:modelValue', newVal);
            }
        },
        methods: {
            search: function () {
                if (this.searchText.length < 1) { return [] }
                
                return this.choices.filter(text => {
                    return text.toLowerCase()
                        .startsWith(this.searchText.toLowerCase())
                })
            },
            onBlur: function ($event) {
                this.searchText = $event;

                if (this.searchText == null || this.searchText == "") {
                    this.$emit('update:modelValue', "");
                    return;
                }

                var rslt = this.choices.filter((item) => (item.toLowerCase().includes(this.searchText.toLowerCase())));
                if (rslt && rslt.length == 1)
                    this.onHit(rslt[0]);
            },
            onHit: function (obj) {
                this.$emit('update:modelValue', obj);
                this.$nextTick(function () {
                    this.searchText = obj;
                });
            }
        },
        mounted: function () {
            if (this.modelValue != null && this.modelValue != '') {
                this.searchText = this.modelValue.toString();
            }
        },
        created: function () {
            this.search();
        }
    }
</script>