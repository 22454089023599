<template>
  <div id="app">
    <div id="BodyContent" v-if="loaded === false">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center">
            <div class="loading-animation mx-auto">
              <div class="loading-animation-content">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="BodyContent" v-else>
      <div class="container" v-if="messages.length > 0">
        <div class="row">
          <div class="col-12">
            <h2 class="h2 pt-2">
              <template v-if="messages.length === 1">
                <span class="fas fa-comment text-info"></span>
                Message from Office of Motor Vehicles
              </template>
              <template v-else>
                <span class="fas fa-comment text-info"></span>
                Messages from Office of Motor Vehicles
              </template>
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12" v-for="message in messages" :key="message.Id">
            <div class="card card-message">
              <div class="card-body pb-0 mt-0 mb-0">
                <p class="font-weight-bolder text-white">
                  <span class="fas fa-comment"></span>
                  {{ message.Summary }}
                  <a class="font-weight-bolder text-decoration-none" :href="`messageDetails.html?message=${message.Id}`" :key="message.Id">Read More.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12">
            <form class="container-fluid white-box" id="filters" v-on:submit.prevent>
              <div class="row">
                <div class="col-12">
                  <h2 class="h4">Search and View</h2>
                </div>
                <div class="col-12 col-lg-4 mt-4">
                  <div class="form-group mb-0">
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        v-model="statusFilter"
                        id="statusFilter"
                        value="Yes"
                      />
                      <label class="custom-control-label" for="statusFilter">
                        Show only Open Offices
                      </label>
                    </div>
                  </div>
                </div>
                <hr class="minor" />
              </div>
              <div class="row">
                <div class="col-12 col-lg-3">
                  <div class="form-group mb-lg-0">
                    <label for="citySelect" class="label">Search by City</label>
                    <select id="citySelect" class="form-control" v-model="cityFilter">
                      <option value="0" v-if="parishFilter === '0'">Select a city</option>
                      <option value="0" v-if="parishFilter !== '0'" v-cloak>
                        Select a city in
                        {{ parishFilter.toDisplayCase() }}
                      </option>
                      <option v-for="city in cityOptions"
                              v-bind:value="city"
                              v-bind:key="city">
                        {{ city.toDisplayCase() }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group mb-lg-0">
                    <label for="parishSelect" class="label">Search by Parish</label>
                    <select id="parishSelect" class="form-control" v-model="parishFilter">
                      <option value="0">Select a parish</option>
                      <option v-for="parish in parishOptions"
                              v-bind:value="parish"
                              v-bind:key="parish">
                        {{ parish.toDisplayCase() }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group mb-lg-0">
                    <label for="officeNameSearch" class="label">Search by Office Name</label>
                    <typeahead id="officeNameSearch" v-model="officeNameFilter" :choices="officeNameChoices"></typeahead>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group mb-lg-0">
                    <label for="serviceSelect" class="label">Search by Office Service</label>
                    <select id="serviceSelect" class="form-control" v-model="serviceFilter">
                      <option value="0">Select a service</option>
                      <option
                              v-for="service in services"
                              v-bind:value="service"
                              v-bind:key="service">
                      {{ service }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="col-12">
            <div class="white-box">
              <div class="row justify-content-center">
                <article class="col-12 col-md-6">
                  <h2 class="h4">
                    <span class="fas fa-building text-primary-10"></span> OMV Offices
                    <br />
                    <br />
                  </h2>
                  <div id="omv-office-group" class="list-group">
                    <div v-if="filteredOmvOffices.length === 0">
                      <div
                        class="list-group-item"
                        id="omv-office-placeholder"
                        v-if="parishFilter === '0' && cityFilter === '0' && officeNameFilter === '' && serviceFilter === '0' && !statusFilter"
                      >Unable to load OMV Office Locations.</div>
                      <div
                        class="list-group-item"
                        id="omv-office-placeholder"
                        v-else-if="parishFilter === '0' && cityFilter === '0' && officeNameFilter === '' && serviceFilter === '0' && statusFilter"
                      >No Open OMV Office Locations.</div>
                      <div
                        class="list-group-item"
                        id="omv-office-placeholder"
                        v-else-if="parishFilter === '0' && cityFilter !== '0' && officeNameFilter === '' && serviceFilter === '0' && !statusFilter"
                      >No OMV Office Locations in that city.</div>
                      <div
                        class="list-group-item"
                        id="omv-office-placeholder"
                        v-else-if="parishFilter !== '0' && cityFilter === '0' && officeNameFilter === '' && serviceFilter === '0' && !statusFilter"
                      >No OMV Office Locations in that parish.</div>
                      <div
                        class="list-group-item"
                        id="omv-office-placeholder"
                        v-else
                      >No OMV Office Locations matching those filters.</div>
                    </div>

                    <a class="list-group-item list-group-item-action d-flex align-items-start text-info"
                       :href="`details.html?office=${office.Id}`"
                       v-for="office in filteredOmvOffices"
                       v-bind:key="office.Id">
                      <span class="fas fa-arrow-alt-circle-right mr-1 align-self-center"></span>
                      <p class="mb-0 align-self-center">
                        <span>{{ office.OfficeName }}</span>
                      </p>
                      <span class="fas fa-comment fa-lg text-danger ml-2 align-self-center"
                            v-if="office.PublicMessages != ''"></span>
                      <span class="badge badge-pill badge-success ml-auto align-self-center"
                            v-if="office.Status === 'Open'">
                        <span class="fas fa-check-circle"></span>
                        Open
                      </span>
                      <span class="badge badge-pill badge-danger ml-auto mt-1 align-self-start"
                            v-if="office.Status === 'Closed'">
                        <span class="fas fa-times-circle"></span>
                        Closed
                      </span>
                      <span class="badge badge-pill badge-warning ml-auto align-self-center"
                            v-if="office.Status === 'Temporary Office Closure'">
                        <span class="fas fa-times-circle"></span>
                        Temporary Office Closure
                      </span>
                    </a>
                  </div>
                </article>
                <article class="col-12 col-md-6 mt-8 mt-md-0">
                  <h2 class="h4">
                    <span class="fas fa-store-alt text-primary-10"></span> Public Tag Agent
                    <br />and Auto Title Company Offices
                  </h2>
                  <div id="pta-atc-office-group" class="list-group">
                    <div v-if="filteredPtaAndAtcOffices.length === 0">
                      <div
                        class="list-group-item"
                        id="omv-office-placeholder"
                        v-if="parishFilter === '0' && cityFilter === '0' && officeNameFilter === '' && serviceFilter === '0' && !statusFilter"
                      >Unable to load Public Tag Agent or Auto Title Company Locations.</div>
                      <div
                        class="list-group-item"
                        id="omv-office-placeholder"
                        v-else-if="parishFilter === '0' && cityFilter === '0' && officeNameFilter === '' && serviceFilter === '0' && statusFilter"
                      >No Open Public Tag Agent or Auto Title Company Locations.</div>
                      <div
                        class="list-group-item"
                        id="omv-office-placeholder"
                        v-else-if="parishFilter === '0' && cityFilter !== '0' && officeNameFilter === '' && serviceFilter === '0' && !statusFilter"
                      >No Public Tag Agent or Auto Title Company Locations in that city.</div>
                      <div
                        class="list-group-item"
                        id="omv-office-placeholder"
                        v-else-if="parishFilter !== '0' && cityFilter === '0' && officeNameFilter === '' && serviceFilter === '0' && !statusFilter"
                      >No Public Tag Agent or Auto Title Company Locations in that parish.</div>
                      <div
                        class="list-group-item"
                        id="omv-office-placeholder"
                        v-else
                      >No Public Tag Agent or Auto Title Company Locations matching those filters.</div>
                    </div>

                    <a class="list-group-item list-group-item-action d-flex align-items-start text-info"
                       :href="`details.html?office=${office.Id}`"
                       v-for="office in filteredPtaAndAtcOffices"
                       v-bind:key="office.Id">
                      <span class="fas fa-arrow-alt-circle-right mr-1 mt-1 align-self-start"></span>
                      <p class="mb-0 align-self-start">
                        <span>{{ office.OfficeName }}</span>
                        <br />
                        <small class="d-block font-weight-bold mt-2">{{ office.PhysicalCity }}</small>
                      </p>
                      <span class="fas fa-comment fa-lg text-danger ml-2 mt-1 align-self-start"
                            v-if="office.PublicMessages != ''"></span>
                      <span class="badge badge-pill badge-success ml-auto mt-1 align-self-start"
                            v-if="office.Status === 'Open'">
                        <span class="fas fa-check-circle"></span>
                        Open
                      </span>
                      <span class="badge badge-pill badge-danger ml-auto mt-1 align-self-start"
                            v-if="office.Status === 'Closed'">
                        <span class="fas fa-times-circle"></span>
                        Closed
                      </span>
                      <span class="badge badge-pill badge-warning ml-auto mt-1 align-self-start"
                            v-if="office.Status === 'Temporary Office Closure'">
                        <span class="fas fa-times-circle"></span>
                        Temporary Office Closure
                      </span>
                    </a>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Typeahead from './TypeaheadComponent.vue';

window.addEventListener("unload", function() {
  document.forms.filters.reset();
});

export default {
  data() {
    return {
      offices: [],
      messages: [],
      services: [],
      cityFilter: "0",
      parishFilter: "0",
      serviceFilter: "0",
      statusFilter: false,
      officeNameFilter: '',
      loaded: false
    };
  },
  components: {
    Typeahead
  },
  computed: {
    parishOptions: function() {
      return this.offices
        .map(function(x) {
          return x.Parish.trim().toUpperCase();
        })
        .filter(function(parish, index, parishes) {
          return parishes.indexOf(parish) === index;
        })
        .sort();
    },
    parishCities: function() {
      let citiesByParish = [];
      this.offices.forEach(function(x) {
        let parish = x.Parish.trim().toUpperCase();
        let city = x.PhysicalCity.trim().toUpperCase();
        let officeName = x.OfficeName.trim().toUpperCase();
        if (!citiesByParish[parish] || !Array.isArray(citiesByParish[parish]))
          citiesByParish[parish] = [];
        if (citiesByParish[parish].indexOf(city) === -1)
          citiesByParish[parish].push(city);
        if (
          x.OfficeType === "FLD" &&
          officeName != city &&
          citiesByParish[parish].indexOf(officeName) === -1
        )
          citiesByParish[parish].push(officeName);
      });
      return citiesByParish;
    },
    cityOptions: function() {
      if (this.parishFilter === "0") {
        return (
          Object.keys(this.parishCities)
            .reduce(
              function(accumulator, currentValue) {
                return accumulator.concat(this.parishCities[currentValue]);
              }.bind(this),
              []
            )
            .filter(function(parish, index, parishes) {
              return parishes.indexOf(parish) === index;
            })
            .sort()
        );
      } else return this.parishCities[this.parishFilter].sort();
    },
    omvOffices: function() {
      return this.offices
        .filter(function(office) {
          return office.OfficeType === "FLD";
        })
        .sort(function(a, b) {
          return a.OfficeName.toUpperCase() > b.OfficeName.toUpperCase()
            ? 1
            : -1;
        });
    },
    ptaAndAtcOffices: function() {
      return this.offices
        .filter(function(office) {
          return office.OfficeType === "PTA" || office.OfficeType === "ATC";
        })
        .sort(this.sortByCityAndOfficeName);
    },
    officeNameChoices: function() {
      return this.offices.map(office => office.OfficeName);
    },
    filteredOmvOffices: function() {
      return this.omvOffices.filter(function(office) {
        return (
          (this.parishFilter === "0" ||
            office.Parish.trim().toUpperCase() === this.parishFilter) &&
          (this.cityFilter === "0" ||
            office.OfficeName.trim().toUpperCase() === this.cityFilter ||
            office.PhysicalCity.trim().toUpperCase() === this.cityFilter) &&
          (this.officeNameFilter === "" || office.OfficeName.startsWith(this.officeNameFilter)) &&
          (this.serviceFilter === "0" || office.Services.findIndex(s => s.trim().toUpperCase() === this.serviceFilter.trim().toUpperCase()) >= 0) &&
          (this.statusFilter === false || office.Status === "Open")
        );
      }, this);
    },
    filteredPtaAndAtcOffices: function() {
      return this.ptaAndAtcOffices.filter(function(office) {
        return (
          (this.parishFilter === "0" ||
            office.Parish.trim().toUpperCase() === this.parishFilter) &&
          (this.cityFilter === "0" ||
            office.PhysicalCity.trim().toUpperCase() === this.cityFilter) &&
          (this.officeNameFilter === "" || office.OfficeName.startsWith(this.officeNameFilter)) &&
          (this.serviceFilter === "0" || office.Services.findIndex(s => s.trim().toUpperCase() === this.serviceFilter.trim().toUpperCase()) >= 0) &&
          (this.statusFilter === false || office.Status === "Open")
        );
      }, this);
    }
  },
  methods: {
    searchOfficeName(input) {
        if (input.length < 1) { return [] }
        return this.officeNameChoices.filter(officeName => {
            return officeName.toLowerCase()
                .startsWith(input.toLowerCase())
        })
    },
    toggleItem(messageId) {
      document
        .getElementById(`AngleButton-${messageId}`)
        .classList.toggle("rotated-180");
    },
    unwrap: function(html) {
      let element = document.createElement("div");
      element.innerHTML = html;
      return element.firstElementChild.innerHTML;
    },
    sortByCityAndOfficeName: function (a, b) {
      if (a.PhysicalCity.trim().toUpperCase() === b.PhysicalCity.trim().toUpperCase()) {
        if (a.OfficeName.trim().toUpperCase() > b.OfficeName.trim().toUpperCase())
          return 1;
        else if (a.OfficeName.trim().toUpperCase() < b.OfficeName.trim().toUpperCase())
          return -1;
        else if (a.OfficeName.trim().toUpperCase() === b.OfficeName.trim().toUpperCase())
          return 0;
      }
      else if (a.PhysicalCity.trim().toUpperCase() > b.PhysicalCity.trim().toUpperCase()) {
         return 1;
      }
      else {
         return -1;
      }
    },
    updateMotorcycleServices: function() {
      for (let i = 0; i < this.offices.length; i++) {
        let services = this.offices[i].Services;
        let idx = services.findIndex((s) => {return s.trim().toUpperCase() === 'Conduct Motorcycle Road Skills Test'.toUpperCase()});
        if (idx >= 0) {
          services.splice(idx, 1);
          services.push('Motorcycle Skills Road Test');
          this.offices[i].Services = services.sort((a,b) => { return a.toLowerCase().localeCompare(b.toLowerCase())});
        }
      }
      let svcs = this.services;
      let indx = svcs.findIndex((s) => {return s.trim().toUpperCase() === 'Conduct Motorcycle Road Skills Test'.toUpperCase()});
      if (indx >= 0) {
        svcs.splice(indx, 1);
        svcs.push('Motorcycle Skills Road Test');
        svcs.sort((a,b) => { return a.toLowerCase().localeCompare(b.toLowerCase())});
      }
    },
    getObjectFromQueryString: function (str) {
      str = str != null && typeof str != 'undefined' ? str : window.location.search;
      const params = new URLSearchParams(window.location.search);
      let obj = {};
      for (const [key, value] of params) {
        if (obj.hasOwnProperty(key)) {
          if (obj[key] instanceof Array)
            obj[key].push(value);
          else
            obj[key] = [obj[key], value];
        }
        else if (value === 'false')
          obj[key] = false;
        else if (value === 'true')
          obj[key] = true;
        else
          obj[key] = value;
      }
      return obj;
    },
  },
  watch: {
    parishFilter: function() {
      this.cityFilter = "0";
    }
  },
  mounted() {
    let request = new XMLHttpRequest();
    request.onload = function() {
      let data = JSON.parse(request.responseText);
      this.offices = data.Offices;
      this.messages = data.Messages;
      this.services = data.Services.map(s => s.Name.trim());
      this.updateMotorcycleServices();
      this.loaded = true;
    }.bind(this);
    let qs = new Date().toISOString();
    request.open("GET", `data.json?q=${qs}`, true);
    request.onerror = function() {};
    request.send();

    let queryFilter = this.getObjectFromQueryString();
    if (queryFilter.hasOwnProperty("hasReinstatement") && queryFilter.hasReinstatement == 1)
      this.serviceFilter = "Reinstatement of Driving Privileges (Including DUI/DWI)";
    if (queryFilter.hasOwnProperty("hasInstallments") && queryFilter.hasInstallments == 1)
      this.serviceFilter = "Installments";
  }
};
</script>

<style lang="scss">
  @import "@/_styles/scss/styles.scss";
</style>
