<template>
  <div id="app">
    <div id="BodyContent" v-if="message == null">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center">
            <div class="loading-animation mx-auto">
              <div class="loading-animation-content">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="BodyContent" v-else>
      <div class="pt-8 bg-white" id="pageTitle">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 ">
              <h2 class="h2 font-weight-light ">
                  <span class="fas fa-comment text-primary-10"></span>
                  Messages
              </h2>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <hr class="major mb-0" />
            </div>
          </div>
        </div>
      </div>
      <div id="BodyContent">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-12">
              <div class="white-box">
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-8">
                    <h2>{{ message.Title }}</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-8">
                    <hr class="minor mb-0" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-3">
                      </div>
                      <div class="col-1 pr-1">
                      </div>
                      <div class="col-8 pl-1">
                        <p class="mb-0 text-primary">
                          Effective:
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                      </div>
                      <div class="col-1 h6 pr-1">
                        <span class="fas fa-calendar-plus"></span>
                      </div>
                      <div class="col-8 pl-1">
                        <p class="h6">{{ formatDate(message.EffectiveDate) }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                      </div>
                      <div class="col-1 pr-1">
                      </div>
                      <div class="col-8 pl-1">
                        <p class="mb-0 text-primary">
                          Expires:
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                      </div>
                      <div class="col-1 h6 pr-1">
                        <span class="fas fa-calendar-times"></span>
                      </div>
                      <div class="col-8 pl-1">
                        <p class="h6">{{ formatDate(message.ExpireDate) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <p class="pt-5" v-html="message.Text"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { parseISO, format } from 'date-fns';

  export default {
    data() {
      return {
        message: null
      };
    },
    computed: {
      messageId: function () {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        return params.get("message");
      },

    },
    methods: {
      formatDate: function (string) {
        return string == null
          ? ""
          : format(parseISO(string), "M/dd/yy");
      }
    },
    mounted() {
      let request = new XMLHttpRequest();
      request.onload = function () {
        let messages = JSON.parse(request.responseText).Messages;
        this.message = messages.filter(function (message) {
          return message.Id == this.messageId;
        }, this)[0];
      }.bind(this);
      let qs = new Date().toISOString();
      request.open("GET", `data.json?q=${qs}`, true);
      request.onerror = function () { };
      request.send();
    }
  };
</script>


<style lang="scss">
  @import "@/_styles/scss/styles.scss";
</style>
